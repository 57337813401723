(function($) {

    $(window).on('load', function () {

        // Components loading animations
        $('.view-animation').viewportChecker({
            classToAdd: 'animated',
            offset: 20
        });

        // Slick Slider (jQuery) - Remove these if not in use 
        $('#section-13 .slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 600,
            dots: false,
            autoplay: false,
            autoplaySpeed: 5000,
            prevArrow: $('#section-13  .prev'),
            nextArrow: $('#section-13  .next'),
            mobileFirst: true,
            adaptiveHeight: true,
            responsive: [ 
                {
                    breakpoint: 768, 
                    settings: {
                        autoplay: true,
                        adaptiveHeight: false 
                    }
                }
            ]
        });
    });
    
    // Inline Video Funcionality
    $(document).on('click', '.inline-video-trigger', function () {
        if ($(this).data('video-id')) {
            if ($(this).hasClass('vimeo')) {
                var iframeHTML = '<iframe src="https://www.vimeo.com/embed/' + $(this).attr('data-video-id') + '?title=0&byline=0&portrait=0?&autoplay=1" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>';
            } else {
                var iframeHTML = '<iframe src="https://www.youtube.com/embed/' + $(this).attr('data-video-id') + '?autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';
            }

            $(this).parent('.video-preview-container').find('.inline-video-trigger').hide();
            $(this).parent('.video-preview-container').find('.overlay').hide();
            $(this).parent('.video-preview-container').find('iframe').remove();
            $(this).parent('.video-preview-container').append(iframeHTML);
        } else {
            console.error('no video ID provided.');
        }
    });

    var resizeTimer;
    $(window).bind('resize load', function () {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
            // Responsive slider for blocks section
            $('#section-1 .header-icons').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000, 
                arrows: false,
                dots: true,
                responsive: [ 
                    {
                        breakpoint: 992, 
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            });
            // Responsive slider for blocks section
            $('#section-2 .slider').not('.slick-initialized').slick({
                infinite: true,
                mobileFirst: true,
                speed: 600,
                slidesToShow: 2,
                slidesToScroll: 2,
                autoplay: true,
                autoplaySpeed: 5000, 
                arrows: false,
                dots: true,
                responsive: [ 
                    {
                        breakpoint: 992, 
                        settings: 'unslick'
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                        }
                    }
                ]
            });
        }, 500)
    });

    setTimeout(function () {
        $('.phone-text em').each(function () {
            var unsliced = $(this).text();
            var sliced = unsliced.slice(0, -2) + "...";
            $(this).text(sliced);
            var linked = "tel:" + unsliced.replace(/\s/g, '');
            $(this).click(function () {
                if ($(window).width() < 1000) {
                    window.location.href = linked;
                } else {
                    $(this).text(unsliced);
                }
            });
        });

    }, 3000);

    var elstolazyload = $('.customlazy');
    var hasloaded = false;
    $(window).scroll(function(){
        if(!hasloaded && $(window).scrollTop() > 100){
            hasloaded = true;
            elstolazyload.each(function(){
                $(this).attr('src', $(this).attr('data-lazy-src'));
            });
        }
    });

    // Sticky Header
    $(window).on("scroll load", function () {
        if ($(window).scrollTop() >= 20) {
            $('header').addClass('scrolled');
        } else {
            $('header').removeClass('scrolled');
        }
    });

    $(document).on('click', 'a[href*="#"]', function (event) {
        event.preventDefault();

        var target = $($(this).attr('href'));

        if (target.length) {
            $('html, body').animate({
                scrollTop: target.offset().top - $('header').outerHeight()
            }, 1500);
        }
    });


})( jQuery );
